import * as React from 'react';
import type {
  TagExtended,
  WebSolutionExtended,
} from '@wix/app-market-collection-widget';
import {
  PromoWidget,
  getCollectionDefinition,
  getWebSolutionData,
  extractTagsDefinition,
} from '@wix/app-market-collection-widget';

import s from './style.scss';
import { config } from '../../../config';
import { getWebSolutionsBiData } from '../biData';
import { Path, Origin } from '../../..';
import {
  openOrAddApp,
  shouldRenderAsLink,
  isBundleAppAndNotInstalled,
  isBundleAppAndInstalled,
  getBundleAppSavedPrice,
  isBundleAppWithVoucher,
  sortSolutionsByInstalled,
} from '../../../common/utils';
import { composer } from '../../../component-decorators/composer';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';
import { Heading } from '@wix/design-system';
import { addUrl } from '../../../common/addUrl';
import { useConfigContext } from '../../../config-context';
import { getReferralInfo, getTagName } from '../../../common/bi-events';
import type {
  WebSolution,
  SectionData,
} from '@wix/ambassador-marketplace/types';
import type { MarketplaceStore } from '../../../component-decorators/with-marketplace-store';

export interface IPromoComponentProps
  extends Partial<InjectedExperimentsProps> {
  marketplaceStore?: MarketplaceStore;
  rootTagDefinition: TagExtended;
  webSolutions: WebSolutionExtended[];
  innerTagsDefinitions: TagExtended[];
  permissions?: string[];
  collimp_id?: string;
}

type IPromoMapper = (
  sectionDTO: any,
  marketplaceStore: MarketplaceStore,
  locale?: string,
  signedInstance?: string,
  impressionId?: any,
) => IPromoComponentProps;

export const promoSectionDTOtoProps: IPromoMapper = (
  sectionDTO: SectionData,
  marketplaceStore: MarketplaceStore,
  locale?: string,
  signedInstance?: string,
  impressionId?: any,
) => {
  const { tagData } = sectionDTO;
  const { tagsToWebSolutions } = tagData;
  const { tagsAndSubCategories, webSolutions } =
    tagsToWebSolutions[0].webSolutions;
  const rootTagDefinition = getCollectionDefinition(
    tagsAndSubCategories[0].tag,
  );
  const innerTagsDefinitions = extractTagsDefinition(tagsToWebSolutions);
  const webSolutionsData: WebSolutionExtended[] = webSolutions.map(
    (solution: WebSolution, appIndex: number) => {
      const images =
        solution.assetsMap.banners ||
        solution.assetsMap.images ||
        solution.assetsMap.screenshots;
      return {
        ...getWebSolutionData(solution),
        appIndex,
        poster: images?.assets?.[0]?.url ?? '',
        showPremiumLabel:
          !marketplaceStore.siteIsPremium &&
          solution.baseInfo.pricing.isRequiredWixPremium,
        isInstalled: !!(
          marketplaceStore.installedApps &&
          marketplaceStore.installedApps?.findIndex(
            (installedApp) => installedApp.appId === solution.baseInfo.id,
          ) > -1
        ),
        isBundleAppNotInstalled: isBundleAppAndNotInstalled(
          solution.baseInfo.id,
          marketplaceStore.bundleApps,
        ),
        isBundleAppInstalled: isBundleAppAndInstalled(
          solution.baseInfo.id,
          marketplaceStore.bundleApps,
        ),
        savedPrice: getBundleAppSavedPrice(
          solution.baseInfo.id,
          marketplaceStore.bundleApps,
        ),
        isVoucherApp: isBundleAppWithVoucher(
          solution.baseInfo.id,
          marketplaceStore.bundleApps,
        ),
      };
    },
  );
  return {
    rootTagDefinition,
    webSolutions: sortSolutionsByInstalled(webSolutionsData),
    innerTagsDefinitions,
    collimp_id: impressionId,
  };
};

export const Promo = composer()
  .withMarketplaceStore()
  .withExperiments()
  .compose(
    ({
      rootTagDefinition,
      webSolutions,
      marketplaceStore,
      experiments,
      permissions,
      collimp_id,
    }: IPromoComponentProps) => {
      const configContext = useConfigContext();
      const { i18n, baseUrl } = configContext;
      const referral = getReferralInfo(null, marketplaceStore.prevRoute);
      const referralTag = getTagName(marketplaceStore.route);

      const biData = getWebSolutionsBiData(
        webSolutions,
        'promo',
        rootTagDefinition.slug,
        marketplaceStore.metaSiteId,
        marketplaceStore.route,
        marketplaceStore.prevRoute,
        marketplaceStore.bundleApps,
        collimp_id,
      );

      return (
        <div data-hook={rootTagDefinition.slug}>
          <Heading
            appearance="H2"
            dataHook="widget-title"
            className={s.promoHeading}
          >
            {rootTagDefinition.name}
          </Heading>
          <span className={s.widgetWrapper}>
            <PromoWidget
              i18n={i18n}
              as={shouldRenderAsLink()}
              solutions={addUrl(baseUrl, webSolutions)}
              solutionsBiData={biData}
              onPromoClickHandler={(solution: WebSolutionExtended) => {
                openOrAddApp(
                  solution,
                  marketplaceStore,
                  biData[solution.appIndex],
                  configContext,
                  experiments,
                  false,
                );
              }}
              onBoxClick={(solution: WebSolutionExtended) =>
                config.goto({
                  path: Path.WEB_SOLUTION,
                  slug: solution.slug,
                  referral,
                  appIndex: solution.appIndex,
                  referralTag,
                  collimp_id,
                  referralSectionName: rootTagDefinition.slug,
                })
              }
              withPermissions={config.origin !== Origin.STANDALONE}
              permissions={permissions}
              hideCTA={true}
              collimp_id={collimp_id}
            />
          </span>
        </div>
      );
    },
  );
