import React from 'react';
import s from './styles/pricing-plans.scss';
import { Box, Card, Heading, Text } from '@wix/design-system';
import { ConfirmSmall } from '@wix/wix-ui-icons-common';
import { PlanPrice } from './plan-price';
import { useEssentials } from '../../contexts/essentials-context';
import { pricingPlansSectionDataHooks } from '../../exported-components/pricing-plans-section/data-hooks';
import type { PlanDescription } from '@wix/app-market-services';
import { EPlanDescription } from '@wix/app-market-services';

export interface PlanBoxProps {
  planTitle: string;
  priceTitle?: string;
  priceData?: PriceData;
  description: PlanDescription;
  priceSubTitle?: string;
  isMobile: boolean;
  index: number;
}

export interface PriceData {
  decodedSign: string;
  price: string;
  period?: string;
}

export function PlanBox({
  planTitle,
  priceTitle,
  priceData,
  description,
  isMobile,
  priceSubTitle,
  index,
}: PlanBoxProps) {
  const { t } = useEssentials();
  const currencySymbol = priceData?.decodedSign;

  function addCurrencySymbol(outline: string, symbol: string | undefined) {
    const decimalNumberRegex = /\d+(\.\d+)?/;

    const match = outline.match(decimalNumberRegex);

    if (!match) {
      return outline;
    }

    const decimalNumber = match[0];
    const decimalNumberIndex = outline.indexOf(decimalNumber);

    return (
      outline.slice(0, decimalNumberIndex) +
      symbol +
      outline.slice(decimalNumberIndex)
    );
  }

  return (
    <Card
      showShadow={false}
      className={s.pricingPlanContainerDynamic}
      dataHook={`plan-box-container-${index}`}
    >
      <Box marginTop={4} marginRight={4} marginBottom={4} marginLeft={4}>
        <Box direction="vertical">
          <Box marginBottom={1}>
            <Text
              dataHook={pricingPlansSectionDataHooks.planBoxTitle}
              size="small"
              weight="normal"
            >
              {planTitle}
            </Text>
          </Box>
          <Box direction="vertical">
            <Box direction="vertical" verticalAlign="bottom" marginBottom={3}>
              {priceTitle ? (
                <Box marginTop="2px">
                  <Heading
                    size="large"
                    dataHook={`plan-box-${index}-price-title`}
                  >
                    {priceTitle}
                  </Heading>
                </Box>
              ) : (
                priceData && (
                  <PlanPrice
                    decodedSign={priceData.decodedSign}
                    price={priceData.price}
                    period={priceData.period ?? t('solution.page.pricingMonth')}
                    isMobile={isMobile}
                    index={index}
                  />
                )
              )}

              {priceSubTitle && (
                <Box>
                  <Text size="medium" weight="thin" secondary>
                    {addCurrencySymbol(priceSubTitle, currencySymbol)}
                  </Text>
                </Box>
              )}
            </Box>

            <Box direction="vertical" align="left" dataHook="plan-description">
              {description.descriptionType === EPlanDescription.CUSTOM_TEXT && (
                <Box
                  direction="vertical"
                  verticalAlign="middle"
                  align="center"
                  key={`plan-${index}-description-costume-text`}
                >
                  <Text className={s.planCustomText}>
                    {description.costumeText}
                  </Text>
                </Box>
              )}
              {description.descriptionType === EPlanDescription.BENEFITS &&
                description.benefits.map((bullet: string, bulletIndex) => {
                  return (
                    <Box
                      key={`plan-${index}-description-line-${bulletIndex}`}
                      direction="horizontal"
                      verticalAlign="middle"
                      align="center"
                      marginBottom={
                        bulletIndex === description.benefits.length - 1
                          ? ''
                          : '9px'
                      }
                      inline
                      className={s.stylingBenefit}
                    >
                      <Box className={s.stylingConfirm}>
                        <ConfirmSmall width="18px" height="18px" />
                      </Box>
                      <Text className={s.benefitTextBigBoxDynamic}>
                        {bullet}
                      </Text>
                    </Box>
                  );
                })}
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}
