import * as React from 'react';
import s from './quick-info.scss';
import type {
  ExternalComponentProps,
  ComponentsWithSiteContextProps,
} from '../types';
import { withEssentialsContext } from '../../contexts/essentials-context';
import { AppBasicInfo } from '../../components/quick-info-elements/app-basic-info';
import { AppGallery } from '../../components/quick-info-elements/app-gallery';
import type { Media } from './types';
import { withSiteContext } from '../../contexts/site-context';
import type { BaseApp } from '../../types';

export interface QuickInfoProps
  extends ExternalComponentProps,
    ComponentsWithSiteContextProps {
  app: BaseApp & { isUpgradable: boolean };
  media: Media[];
  onCompanyClick: (companyName: string, companySlug: string) => void;
  onReviewsClick: (reviewsAmount: number) => void;
  onGalleryChange: (oldIndex: number, newIndex: number) => void;
  mainActionButton: React.ReactNode;
}

function QuickInfoComp({
  app,
  media,
  onCompanyClick,
  mainActionButton,
  onReviewsClick,
  onGalleryChange,
}: QuickInfoProps) {
  return (
    <div className={s.quickInfo} data-hook="quick-info-section">
      <AppBasicInfo
        app={app}
        onCompanyClick={onCompanyClick}
        onReviewsClick={onReviewsClick}
        mainActionButton={mainActionButton}
      />
      <AppGallery media={media} onGalleryChange={onGalleryChange} />
    </div>
  );
}

export const QuickInfo = withSiteContext(withEssentialsContext(QuickInfoComp));
