import type { ButtonSize } from '@wix/design-system';
import { Box, Button, Loader } from '@wix/design-system';
import React from 'react';
import { useTranslation } from '../../../../contexts/translations-context';
import { useServices, useSiteContext } from '../../../../contexts';
import { useUpgradeButtonBiEvents } from './use-upgrade-bi-event';
import { QueryDecorator } from '../../../../decorators';

export const UPGRADE_APP_BUTTON_DATA_HOOK = 'upgrade-app-button';

export interface UpgradeAppButtonProps {
  app: { id: string; instanceId?: string };
  size: ButtonSize;
}

export function UpgradeAppButton({ app, size }: UpgradeAppButtonProps) {
  const services = useServices();
  const { t } = useTranslation();
  const { metaSiteId } = useSiteContext();
  const { reportUpgradeClick, reportFreeTrialClick } =
    useUpgradeButtonBiEvents(app);

  return (
    <QueryDecorator
      queryFn={async () => {
        const freeTrialAppIds = await (metaSiteId
          ? services.getEligibleAppsForFreeTrial()
          : Promise.resolve([]));

        return freeTrialAppIds.includes(app.id);
      }}
      queryKey={`upgrade-app-button-${app.id}`}
      csrOnly
    >
      {({ data: isAppEligibleForFreeTrial, isLoading }) => {
        return (
          <Box direction="vertical" flexGrow={1}>
            <Button
              dataHook={UPGRADE_APP_BUTTON_DATA_HOOK}
              skin={isAppEligibleForFreeTrial ? 'standard' : 'premium'}
              size={size}
              onClick={() => {
                if (isLoading) {
                  return;
                }

                if (isAppEligibleForFreeTrial) {
                  reportFreeTrialClick();
                } else {
                  reportUpgradeClick();
                }

                const appPackagePickerUrl = `https://www.wix.com/apps/upgrade/${app.id}?metaSiteId=${metaSiteId}&pp_origin=app_market_app_page`;
                window.open(appPackagePickerUrl);
              }}
            >
              {isLoading ? (
                <Loader size="tiny" />
              ) : isAppEligibleForFreeTrial ? (
                t('app.page.upgrade.cta.label.free.trial')
              ) : (
                t('app.page.upgrade.cta.label')
              )}
            </Button>
          </Box>
        );
      }}
    </QueryDecorator>
  );
}
