import React, { useState } from 'react';
import { Box, Cell, Heading, Layout } from '@wix/design-system';
import { withEssentialsContext } from '../../contexts/essentials-context';
import { AppGrid } from '../app-grid';
import type { AppGroup } from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/types';
import { AppPromoGrid } from '../app-promo-grid';
import type {
  ExternalComponentProps,
  ComponentsWithSiteContextProps,
} from '../types';
import { VisibilityDetector } from '../visibility-detector';
import { appsSectionDataHooks } from './data-hooks';
import { useSiteContext, withSiteContext } from '../../contexts/site-context';
import { ViewAllAppsButton } from './view-all-apps-button';
import s from './apps-section.scss';
import type { BaseApp } from '../../types';

export interface AppsSectionProps
  extends Required<Omit<AppGroup, 'rows'>>,
    ExternalComponentProps,
    ComponentsWithSiteContextProps {
  titleSize?: 'large' | 'medium';
  onButtonClick?: () => void;
  columns?: 1 | 2 | 3 | 4;
  minColumns?: 1 | 2 | 3 | 4;
  rows?: number;
  dataHook?: string;
  onAppClick?(app: BaseApp, appIndex: number, impressionId: string): void;
  onAppLoad?(app: BaseApp, appIndex: number, impressionId: string): void;
  onSectionLoad?(): void;
  showBorder?: boolean;
  getHref?(appSlug: string, appIndex: number): string | undefined;
  buttonHref?: string;
}

function AppsSectionComponent({
  i18n,
  onButtonClick,
  onAppClick,
  columns = 3,
  minColumns,
  titleSize = 'large',
  rows,
  dataHook,
  onAppLoad,
  onSectionLoad,
  showBorder,
  getHref,
  buttonHref,
  ...appGroupProps
}: AppsSectionProps) {
  const { title, apps, type, totalAppsCount } = appGroupProps;
  const { isPremiumSite } = useSiteContext();
  const [finalColumnsCount, setFinalColumnsCount] = useState<number>(columns);

  const showViewAllAppsButton =
    onButtonClick &&
    ((finalColumnsCount === 3 && totalAppsCount > 6) ||
      ((finalColumnsCount === 4 || finalColumnsCount === 1) &&
        totalAppsCount > 8));

  return (
    <div data-hook={appsSectionDataHooks.container}>
      <Box
        direction="vertical"
        gap={titleSize === 'large' ? '18px' : '12px'}
        dataHook={dataHook}
      >
        <Box direction="vertical">
          <Layout>
            <Cell span={10}>
              <Heading size={titleSize} dataHook={appsSectionDataHooks.title}>
                {title}
              </Heading>
            </Cell>
            <Cell span={2}>
              {showViewAllAppsButton && (
                <Box
                  className={s.topButton}
                  align="right"
                  verticalAlign="bottom"
                  height="100%"
                >
                  <ViewAllAppsButton
                    onClick={onButtonClick}
                    href={buttonHref}
                    appsCount={totalAppsCount}
                  />
                </Box>
              )}
            </Cell>
          </Layout>
        </Box>
        <VisibilityDetector
          onFirstVisible={onSectionLoad}
          style={{ height: '100%' }}
        >
          {type === 'PROMO' ? (
            <AppPromoGrid
              dataHook={`${dataHook}-promo-grid`}
              apps={apps}
              i18n={i18n}
              onAppClick={onAppClick}
              onAppLoad={onAppLoad}
              getHref={getHref}
              isPremiumSite={isPremiumSite}
            />
          ) : (
            <AppGrid
              dataHook={`${dataHook}-app-grid`}
              apps={apps}
              i18n={i18n}
              onAppClick={onAppClick}
              columns={columns}
              rows={rows}
              minColumns={minColumns}
              onAppLoad={onAppLoad}
              getHref={getHref}
              isPremiumSite={isPremiumSite}
              showBorder={showBorder}
              finalColumnsCount={(finalColumns: number) =>
                setFinalColumnsCount(finalColumns)
              }
            />
          )}
        </VisibilityDetector>
        {showViewAllAppsButton && (
          <Box className={s.bottomButton}>
            <ViewAllAppsButton
              onClick={onButtonClick}
              href={buttonHref}
              appsCount={totalAppsCount}
            />
          </Box>
        )}
      </Box>
    </div>
  );
}

export const AppsSection = withSiteContext(
  withEssentialsContext(AppsSectionComponent),
);
