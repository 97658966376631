import type { ReactElement } from 'react';
import React, { useMemo } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { isSSR } from '@wix/app-market-services';

type QueryFn<T> = () => Promise<T>;
type ChildrenProps<T> =
  | {
      data: T;
      isLoading: false;
    }
  | {
      data: null;
      isLoading: true;
    };
type Children<T> = (props: ChildrenProps<T>) => ReactElement;

interface QueryDecoratorProps<T> {
  queryFn: QueryFn<T>;
  children: Children<T>;
  queryKey: string;
  csrOnly?: boolean;
}

function createSSRQuery<T>(
  queryKey: string,
): ({ queryFn }: { queryFn: QueryFn<T> }) => T {
  let error: Error;
  let data: T | undefined;
  let promise: Promise<T> | undefined;

  return ({ queryFn }) => {
    const queryClient = useQueryClient();

    if (data) {
      return data;
    }

    if (error) {
      throw new Error(
        `Query with queryKey ${queryKey} failed with error: ${error}`,
      );
    }

    if (!promise) {
      promise = queryClient
        .prefetchQuery([queryKey], queryFn)
        .then(() => {
          data = queryClient.getQueryData<T>([queryKey]);
          if (!data) {
            throw new Error(`Query with queryKey ${queryKey} returned no data`);
          }
          return data;
        })
        .catch((e: Error) => {
          error = e;
          throw e;
        });
    }

    throw promise;
  };
}

function SSRRenderer<T>({
  query,
  queryFn,
  children: Children,
}: {
  query: ({ queryFn }: { queryFn: QueryFn<T> }) => T;
  queryFn: QueryFn<T>;
  children: Children<T>;
}) {
  const data = query({ queryFn });

  return <Children data={data} isLoading={false} />;
}

function createClientQuery<T>(
  queryKey: string,
): ({ queryFn }: { queryFn: QueryFn<T> }) => T {
  return ({ queryFn }) => {
    const { data, error, isLoading } = useQuery<T, Error>([queryKey], queryFn, {
      staleTime: 100000,
    });

    if (isLoading) {
      throw new Promise(() => {}); // Suspend rendering during loading
    }

    if (error) {
      throw new Error(`Query with queryKey ${queryKey} returned no data`);
    }

    return data;
  };
}

function CSRRenderer<T>({
  query,
  queryFn,
  children: Children,
}: {
  query: ({ queryFn }: { queryFn: QueryFn<T> }) => T;
  queryFn: QueryFn<T>;
  children: Children<T>;
}) {
  try {
    const data = query({ queryFn });

    return <Children data={data} isLoading={false} />;
  } catch (errorOrPromise) {
    if (errorOrPromise instanceof Promise) {
      return <Children data={null} isLoading={true} />;
    }

    throw errorOrPromise;
  }
}

/**
 * QueryDecorator for fetching data on SSR & CSR.
 */
export function QueryDecorator<T>({
  queryFn,
  children,
  queryKey,
  csrOnly = false,
}: QueryDecoratorProps<T>) {
  const ssrQuery = useMemo(() => createSSRQuery<T>(queryKey), [queryKey]);
  const clientQuery = useMemo(() => createClientQuery<T>(queryKey), [queryKey]);

  if (isSSR() && !csrOnly) {
    return (
      <SSRRenderer<T> query={ssrQuery} queryFn={queryFn}>
        {children}
      </SSRRenderer>
    );
  }

  return (
    <CSRRenderer<T> query={clientQuery} queryFn={queryFn}>
      {children}
    </CSRRenderer>
  );
}
