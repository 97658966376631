import { Box } from '@wix/design-system';
import React from 'react';
import { SearchBox } from '@wix/app-market-components';
import { useSearchBoxActions } from '../search-box/hooks/search-box-actions';
import { useEssentials } from '../../contexts';

export function SidebarHeader() {
  const { i18n, experiments } = useEssentials();
  const { onSelect, onChange, onFocus, onEnterPressed } = useSearchBoxActions(
    'sidebar',
    'sidebar-header',
  );

  return (
    <Box direction="vertical" paddingTop="8px">
      <Box
        align="center"
        verticalAlign="middle"
        padding="18px 20px 6px"
        paddingBottom="10px"
      >
        <SearchBox
          onChange={onChange}
          onSelect={onSelect}
          onEnterPressed={onEnterPressed}
          onFocus={onFocus}
          i18n={i18n}
          experiments={experiments}
          size="small"
        />
      </Box>
    </Box>
  );
}
