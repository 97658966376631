import {
  useEssentials,
  useJunkYard,
  useRouterContext,
} from '../../../../contexts';
import {
  appMarketAppPageAppGalleryClick,
  appMarketBackButtonClick,
  appMarketAppPageClick,
} from '@wix/bi-logger-market/v2';
import {
  appMarketCategorySubCategoryClickFromTheAppPageSrc24Evid159,
  appMarketInContextAppPageSeeFullDescription,
} from '@wix/bi-logger-app-market-data/v2';
import type { RoutePayloadMap } from '../../../../models';
import { RoutePath, Path } from '../../../../models';
import { getReferralInfoFromCurrentUrl } from '../../../../bi-services';

interface QuickInfoActions {
  onBack(appId: string): void;
  onTagClick(
    categorySlug: string,
    subCategory: string,
    appId: string,
    label: 'category' | 'sub_category',
    location: 'top_page' | 'side_category_page',
  ): void;
  onGalleryChange(oldIndex: number, newIndex: number, appId: string): void;
  onCompanyClick(companyName: string, companySlug: string, appId: string): void;
  onReviewsClick(appId: string, reviewsAmount: number): void;
  onExpandDescriptionButtonClick(appId: string): void;
}

export const useQuickInfoActions = (): QuickInfoActions => {
  const { biLogger, experiments } = useEssentials();
  const { router } = useRouterContext();
  const { goto } = useJunkYard();

  function onBack(appId: string) {
    biLogger.report(
      appMarketBackButtonClick({
        appId,
        tag_type: 'app_page',
        tag_name: 'app_id',
        location: 'page',
      }),
    );
    router.hasHistory() &&
      router.navigateTo({
        path: RoutePath.BACK,
        payload: undefined,
      });
  }

  function onTagClick(
    categorySlug: string,
    subCategorySlug: string,
    appId: string,
    label: 'category' | 'sub_category',
    location: 'top_page' | 'side_category_page',
  ) {
    biLogger.report(
      appMarketCategorySubCategoryClickFromTheAppPageSrc24Evid159({
        category_name: categorySlug,
        subcategory_name: subCategorySlug,
        label_type: label,
        app_id: appId,
        app_page_location: location,
      }),
    );
    if (experiments.enabled('specs.marketplace.routerOnNewInfra')) {
      const categoryNavigation = {
        path: RoutePath.CATEGORY as RoutePath.CATEGORY,
        payload: {
          slug: categorySlug,
        } as RoutePayloadMap['CATEGORY'],
      };
      const subCategoryNavigation = {
        path: RoutePath.SUB_CATEGORY as RoutePath.SUB_CATEGORY,
        payload: {
          parentSlug: categorySlug,
          slug: subCategorySlug,
          subCat: subCategorySlug,
        } as RoutePayloadMap['SUB_CATEGORY'],
      };
      router.navigateTo(
        label === 'sub_category' ? subCategoryNavigation : categoryNavigation,
      );
    } else {
      goto({
        path: Path.CATEGORY,
        slug: categorySlug,
        subCategories: label === 'sub_category' ? [subCategorySlug] : undefined,
      });
    }
  }

  function onGalleryChange(oldIndex: number, newIndex: number, appId: string) {
    const action = oldIndex < newIndex ? 'right' : 'left';
    biLogger.report(
      appMarketAppPageAppGalleryClick({
        action,
        app_id: appId,
        tag_name: 'app_page',
      }),
    );
  }

  function onCompanyClick(
    companyName: string,
    companySlug: string,
    appId: string,
  ) {
    biLogger.report(
      appMarketAppPageClick({
        app_id: appId,
        cta: 'company_name',
        location: 'info',
        value: companyName,
      }),
    );
    if (experiments.enabled('specs.marketplace.routerOnNewInfra')) {
      router.navigateTo({
        path: RoutePath.DEVELOPER,
        payload: {
          slug: companySlug,
        },
      });
    } else {
      goto({
        path: Path.DEVELOPER,
        slug: companySlug,
      });
    }
  }

  function onReviewsClick(appId: string, reviewsAmount: number) {
    biLogger.report(
      appMarketAppPageClick({
        app_id: appId,
        cta: 'review_number',
        location: 'info',
        value: reviewsAmount.toString(),
      }),
    );
    scrollToReviewsSection();
  }

  function onExpandDescriptionButtonClick(appId: string) {
    biLogger.report(
      appMarketInContextAppPageSeeFullDescription({
        app_id: appId,
        referral_info: getReferralInfoFromCurrentUrl(),
      }),
    );
  }

  return {
    onBack,
    onTagClick,
    onCompanyClick,
    onGalleryChange,
    onReviewsClick,
    onExpandDescriptionButtonClick,
  };
};

function scrollToReviewsSection() {
  const reviewsDivider = document.querySelector(
    '[data-hook="reviews-top-divider"]',
  );
  const stickyHeaderElement = document.querySelector(
    '[data-hook="app-page-sticky"]',
  );
  if (!reviewsDivider || !stickyHeaderElement) {
    return;
  }
  const scrollableAncestor = getScrollableAncestor(reviewsDivider);

  const distanceBetweenReviewsDividerToTopOfViewport =
    reviewsDivider.getBoundingClientRect().top;
  const distanceBetweenStickyHeaderBottomToTopOfViewport =
    stickyHeaderElement.getBoundingClientRect().bottom;

  scrollableAncestor.scroll({
    top:
      distanceBetweenReviewsDividerToTopOfViewport -
      distanceBetweenStickyHeaderBottomToTopOfViewport,
    behavior: 'smooth',
  });
}

function getScrollableAncestor(element: Element | null): Element | Window {
  if (!element) {
    return window;
  }

  const { overflowY } = window.getComputedStyle(element);

  const isElementScrollable =
    /(auto|scroll)/.test(overflowY) &&
    element.scrollHeight > element.clientHeight;

  if (isElementScrollable) {
    return element;
  }

  return getScrollableAncestor(element.parentElement);
}
