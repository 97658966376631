import * as React from 'react';
import s from './app-basic-info.scss';
import { Box, Image, Heading, Text, TextButton } from '@wix/design-system';
import { AppInfoBadge } from '../../app-info-badge';
import { type PricingBadgeProps } from '../../pricing-badge';
import { useEssentials } from '../../../contexts/essentials-context';
import { useSiteContext } from '../../../contexts/site-context';
import { shouldShowPremiumRequired } from '../../app-info-badge/app-info-badge.util';
import { Badges } from '../../badges/badges';
import { ReviewsSummary } from '../reviews-summary';
import type { BaseApp } from '../../../types';

export interface AppBasicInfoProps {
  app: BaseApp & { isUpgradable: boolean };
  onCompanyClick: (companyName: string, companySlug: string) => void;
  onReviewsClick: (reviewsAmount: number) => void;
  mainActionButton: React.ReactNode;
}

export function AppBasicInfo({
  app,
  onCompanyClick,
  onReviewsClick,
  mainActionButton,
}: AppBasicInfoProps) {
  const {
    name,
    company,
    icon,
    shortDescription,
    reviews,
    pricing,
    installation,
    bundle,
    badges,
  } = app;
  const { t } = useEssentials();
  const { isPremiumSite } = useSiteContext();

  return (
    <Box align="space-between" marginTop="12px" paddingRight="60px">
      <Box gap="12px">
        <Box
          flexShrink={0}
          className={s.iconWrapper}
          marginTop={badges?.length === 0 ? '6px' : '3px'}
        >
          <Image
            dataHook="logo"
            width="54px"
            height="54px"
            src={icon}
            alt={`${name} logo`}
          />
        </Box>
        <Box direction="vertical">
          <Box gap="6px" marginBottom="2px">
            <Badges
              badges={badges}
              size="small"
              bundle={bundle}
              isInstalled={!!installation?.isInstalled}
              appName={name ?? ''}
            />
          </Box>
          <Box
            className={s.basicInfoTitle}
            direction="vertical"
            width="206px"
            marginBottom="30px"
          >
            <Heading dataHook="app-name-heading" size="large">
              {name}
            </Heading>
            {company?.name && (
              <Box gap="2px">
                <Text size="small">{t('solution.page.developerName.by')}</Text>
                <TextButton
                  onClick={() =>
                    onCompanyClick(company?.name ?? '', company?.slug ?? '')
                  }
                  dataHook="company-name"
                  size="small"
                >
                  {company?.name}
                </TextButton>
              </Box>
            )}
          </Box>
          <Box direction="vertical" width="258px" dataHook="boxchecking">
            <Box marginBottom="12px">
              <Text>{shortDescription}</Text>
            </Box>

            <Box gap="8px" marginBottom="30px">
              <ReviewsSummary
                totalReviewsCount={reviews?.totalCount ?? 0}
                averageRating={reviews?.averageRating ?? 0}
                onClick={onReviewsClick}
              />
            </Box>
          </Box>
          <Box>
            <Box direction="vertical">
              {mainActionButton}
              <Box
                align="center"
                marginTop="12px"
                gap="18px"
                verticalAlign="middle"
              >
                <AppInfoBadge
                  isAppInstalled={!!installation?.isInstalled}
                  isAppBuiltIn={!!installation?.isBuiltIn}
                  isAppSetupIncomplete={!!installation?.isSetupIncomplete}
                  isBundleApp={!!bundle?.isBundleApp}
                  pricing={pricing as PricingBadgeProps}
                  options={{
                    showPremiumRequired: shouldShowPremiumRequired(
                      !!installation?.requirements?.premiumSite,
                      isPremiumSite,
                    ),
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
