import * as React from 'react';
import type { DropdownLayoutOption } from '@wix/design-system';
import {
  Search,
  listItemSectionBuilder,
  listItemSelectBuilder,
  Image,
  Text,
} from '@wix/design-system';
import { useEffect, useState } from 'react';
import {
  useEssentials,
  withEssentialsContext,
} from '../../contexts/essentials-context';
import type { ExternalComponentProps } from '../types';
import { OptionTypes } from './option-types';
import {
  createOptionIdWithParams,
  parseOnSelectParams,
} from './search-dropdown-suggestion-id-service';
import type { OnSelectParams } from './search-dropdown-types';

const { APP, SEARCH } = OptionTypes;

export type AppSuggestion = {
  id: string;
  name: string;
  teaser: string;
  icon: string;
  slug: string;
};
export interface SearchBoxProps extends ExternalComponentProps {
  onChange(searchTerm: string): Promise<{
    autocompleteSuggestions: string[];
    appsSuggestions: AppSuggestion[];
  }>;
  onSelect: (params: OnSelectParams) => void;
  onEnterPressed: (searchTerm: string) => void;
  onFocus: () => void;
  givenSearchTerm?: string;
  size: 'small' | 'medium' | 'large';
}

function SearchBoxComponent({
  onChange,
  onSelect,
  onEnterPressed,
  experiments,
  onFocus,
  givenSearchTerm,
  size = 'large',
}: SearchBoxProps) {
  const { t } = useEssentials();
  const [searchTerm, setSearchTerm] = useState<string>(givenSearchTerm || '');
  const [status, setStatus] = useState<'loading' | undefined>(undefined);
  function getDefaultOptions() {
    const defaultOptions = [
      t('search-box.default-suggestions.1'),
      t('search-box.default-suggestions.2'),
      t('search-box.default-suggestions.3'),
      t('search-box.default-suggestions.4'),
      t('search-box.default-suggestions.5'),
    ];
    const options: DropdownLayoutOption[] = defaultOptions.map(
      (option, index) => {
        return listItemSelectBuilder({
          id: createOptionIdWithParams({ index: index + 1, type: SEARCH }),
          title: (
            <Text size="small" weight="bold">
              {option}
            </Text>
          ),
          label: option,
        });
      },
    );
    const title: DropdownLayoutOption[] = [
      listItemSectionBuilder({
        title: t('search.box.default.suggestions.title'),
        id: 0,
      }),
    ];
    return title.concat(options);
  }
  const [options, setOptions] = useState<DropdownLayoutOption[]>(
    getDefaultOptions(),
  );

  function getAutoCompleteOptions(autoCompleteSuggestions: string[]) {
    return autoCompleteSuggestions.map((suggestion, index) => {
      return listItemSelectBuilder({
        title: (
          <Text size="small" weight="bold">
            {suggestion}
          </Text>
        ),
        label: suggestion,
        id: createOptionIdWithParams({ index, type: SEARCH }),
      });
    });
  }

  function getAppsOptions(appsSuggestions: AppSuggestion[]) {
    const appsSuggestionsOptions = appsSuggestions.map((suggestion, index) => {
      const { name, teaser, id, slug, icon } = suggestion;
      return listItemSelectBuilder({
        prefix: <Image width="30px" height="30px" src={icon} alt="" />,
        title: name,
        subtitle: teaser,
        id: createOptionIdWithParams({ index, type: APP, appId: id, slug }),
        label: name,
        size: 'small',
        dataHook: `app-suggestion-${id}`,
      });
    });
    const appSuggestionTitle: DropdownLayoutOption[] = [
      listItemSectionBuilder({
        title: t('search.box.apps.title'),
        type: 'subheader',
        id: 'apps for your site',
      }),
    ];
    const seeMoreAppsOption: DropdownLayoutOption[] = [
      listItemSelectBuilder({
        title: (
          <Text size="small" skin="primary">
            {t('search.box.more')}
          </Text>
        ),
        id: 'see more apps',
      }),
    ];
    return appsSuggestionsOptions.length
      ? appSuggestionTitle
          .concat(appsSuggestionsOptions)
          .concat(seeMoreAppsOption)
      : [];
  }

  async function onChangeSearchTerm() {
    setOptions([]);
    if (!searchTerm.length) {
      setOptions(getDefaultOptions());
    } else {
      setStatus('loading');
      const { autocompleteSuggestions, appsSuggestions } = await onChange(
        searchTerm,
      );
      const autocompleteOptions = getAutoCompleteOptions(
        autocompleteSuggestions,
      );
      const shouldUseAppsSuggestions =
        experiments &&
        experiments?.enabled('spec.appMarket.showAppsSuggestions');
      const _options = shouldUseAppsSuggestions
        ? [...autocompleteOptions, ...getAppsOptions(appsSuggestions)]
        : autocompleteOptions;
      setOptions(_options);
    }
    setStatus(undefined);
  }

  useEffect(() => {
    onChangeSearchTerm();
  }, [searchTerm]);

  return (
    <Search
      dataHook="app-market-search-box"
      size={size}
      onSelect={(option) => onSelect(parseOnSelectParams(option, searchTerm))}
      clearButton={status && !searchTerm}
      placeholder={t('search.box.place.holder')}
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      onClear={() => setSearchTerm('')}
      options={options}
      maxHeightPixels="700px"
      status={status}
      predicate={() => true}
      onEnterPressed={() => onEnterPressed(searchTerm)}
      onFocus={onFocus}
      popoverProps={{
        placement: 'bottom-start',
        appendTo: 'window',
        zIndex: 2000,
      }}
    />
  );
}

export const SearchBox = withEssentialsContext(SearchBoxComponent);
