import s from '../pages.scss';
import React, { useEffect, useRef, useState } from 'react';
import { Box, Loader, Page, Text } from '@wix/design-system';
import { AppsSection, PageHeader } from '@wix/app-market-components';
import {
  useEssentials,
  useJunkYard,
  useRouterContext,
  useServices,
} from '../../contexts';
import { QueryDecorator } from '../../decorators';
import { getCollectionPageData, getApps } from './collection-page-data';
import type { CollectionPageData } from './collection-page-data';
import { useAppsSectionSharedPropsMapper } from '../../hooks/apps-section-props-mapper';
import { AppMarketPage } from '../../enums';
import { useTranslation } from '../../contexts/translations-context';
import type { AppGroupSection, AppDataType } from '@wix/app-market-services';
import { getPageViewBiData } from '../../bi-services/events-data-parser';
import {
  appMarketBackButtonClick,
  appMarketPageView,
} from '@wix/bi-logger-market/v2';
import { appMarketPageStartLoadedSrc24Evid163 } from '@wix/bi-logger-app-market-data/v2';
import { RoutePath } from '../../models';
import { CollectionSkeleton } from './collection-skeleton';
import { BackButton } from '../../components/back-button';

function CollectionPageComponent({
  slug,
  appGroup,
  paging,
  tagId,
  primaryTitle,
  secondaryTitle,
  description,
}: CollectionPageData) {
  const appsSectionSharedPropsMapper = useAppsSectionSharedPropsMapper();
  const { routerData } = useJunkYard();
  const { biLogger } = useEssentials();
  const { router } = useRouterContext();
  const { t } = useTranslation();
  const services = useServices();
  const page = AppMarketPage.COLLECTION;
  const ref = useRef<HTMLDivElement>(null);
  const [apps, setApps] = useState<AppDataType[]>(
    appGroup.apps as AppDataType[],
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [moreAppsToLoad, setMoreAppsToLoad] = useState<boolean>(paging.hasNext);

  function onBack() {
    biLogger.report(
      appMarketBackButtonClick({
        tag_type: page,
        tag_name: slug,
        location: 'collection_page',
      }),
    );
    router.navigateTo({
      path: RoutePath.BACK,
      payload: undefined,
    });
  }

  async function fetchMoreApps() {
    setIsLoading(true);
    const { apps: nextApps, hasNext } = await getApps({
      services,
      tagId,
      offset: apps.length,
    });
    setIsLoading(false);

    setMoreAppsToLoad(nextApps.length > 0 && hasNext);
    setApps((prevApps) => [...prevApps, ...nextApps]);
  }

  function onIntersection(entries: IntersectionObserverEntry[]) {
    const isIntersecting = entries.some((entry) => entry.isIntersecting);
    if (isIntersecting && moreAppsToLoad) {
      fetchMoreApps();
    }
  }

  useEffect(() => {
    const urlParams = location.pathname.split('/')[3];
    biLogger.report(
      appMarketPageView(
        getPageViewBiData({
          routerData,
          urlParams,
          pageType: page,
          pageName: slug,
        }),
      ),
    );
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(onIntersection);
    if (moreAppsToLoad) {
      observer.observe(ref.current!);
    }
    return () => {
      observer.disconnect();
    };
  }, [apps]);

  return (
    <Page className={`${s.appMarketPage} ${s.appMarketPageBackgroundColor}`}>
      <Page.Content>
        {router.hasHistory() && (
          <Box marginTop="18px">
            <BackButton
              onBack={onBack}
              size="small"
              weight="thin"
              skin="dark"
            />
          </Box>
        )}
        <Box
          dataHook="collection-page-header"
          marginTop={router.hasHistory() ? '6px' : '18px'}
        >
          <PageHeader
            primaryTitle={secondaryTitle}
            secondaryTitle={primaryTitle}
            description={description}
          />
        </Box>
        <Box marginTop="24px" marginBottom="-6px">
          <Text weight="normal" size="small" secondary>
            {t('collection.apps-counter', { appsCount: paging.total })}{' '}
          </Text>
        </Box>
        <Box
          ref={ref}
          dataHook="collection-page-apps-section-box"
          direction="vertical"
        >
          <AppsSection
            {...appsSectionSharedPropsMapper(
              { ...appGroup, apps: apps as AppGroupSection['apps'] },
              page,
              slug,
              0,
            )}
          ></AppsSection>
        </Box>
        {isLoading && (
          <Box verticalAlign="middle" align="center" marginTop="24px">
            <Loader size="medium" />
          </Box>
        )}
      </Page.Content>
    </Page>
  );
}

export function CollectionPage({ slug }: { slug: string }) {
  const { biLogger, i18n } = useEssentials();
  const services = useServices();

  useEffect(() => {
    biLogger.report(
      appMarketPageStartLoadedSrc24Evid163({
        tag_type: AppMarketPage.COLLECTION,
      }),
    );
  }, [slug]);

  return (
    <QueryDecorator
      queryFn={() =>
        getCollectionPageData({
          services,
          slug,
          languageCode: i18n.language,
        })
      }
      queryKey={`collection-page-${slug}-${i18n.language}`}
    >
      {({ data: collectionPageData, isLoading }) =>
        isLoading ? (
          <CollectionSkeleton />
        ) : (
          <CollectionPageComponent {...collectionPageData} />
        )
      }
    </QueryDecorator>
  );
}
