import { stringify } from 'query-string';
import type { QueryMap } from '../query';
import { RoutePath } from './routes';
import type { IRoute, RoutePayloadMap } from './routes';
import type { IRoute as OldRoute } from '../junk-yard';
import { Path } from '../junk-yard';
import { paramsRoute } from './params-route';

export class Route {
  private readonly path: RoutePath;
  private readonly payload: RoutePayloadMap[RoutePath];
  private readonly baseURL: string | undefined;

  constructor({
    path,
    payload,
    baseURL,
  }: {
    path: RoutePath;
    payload: RoutePayloadMap[RoutePath];
    baseURL: string | undefined;
  }) {
    this.path = path;
    this.payload = payload;
    this.baseURL = baseURL;
  }

  static toRoute = ({
    path,
    payload,
    slug,
    subSlug,
    baseURL,
  }: {
    path: string;
    payload: Partial<QueryMap>;
    slug?: string;
    subSlug?: string;
    baseURL: string | undefined;
  }): Route => {
    if (payload.appMarketParams || payload.openAppDefId) {
      return paramsRoute({ payload, baseURL, slug });
    }
    switch (path) {
      case Path.COLLECTION:
        return new Route({
          path: RoutePath.COLLECTION,
          payload: { ...payload, slug: slug! },
          baseURL,
        });
      case Path.CATEGORY:
        if (subSlug) {
          return new Route({
            path: RoutePath.SUB_CATEGORY,
            payload: {
              ...payload,
              parentSlug: slug!,
              slug: subSlug,
            },
            baseURL,
          });
        }
        return new Route({
          path: RoutePath.CATEGORY,
          payload: { ...payload, slug: slug! },
          baseURL,
        });
      case Path.WEB_SOLUTION:
        return new Route({
          path: RoutePath.APP_PAGE,
          payload: { ...payload, appId: slug!, slug },
          baseURL,
        });
      case Path.SEARCH:
        return new Route({
          path: RoutePath.SEARCH_RESULTS,
          payload,
          baseURL,
        });
      case Path.DEVELOPER:
        return new Route({
          path: RoutePath.DEVELOPER,
          payload: { slug },
          baseURL,
        });
      case Path.HOME:
        return new Route({
          path: RoutePath.HOME,
          payload,
          baseURL,
        });
      case Path.MANAGE_APPS:
        return new Route({
          path: RoutePath.MANAGE_APPS,
          payload,
          baseURL,
        });
      default:
        return new Route({
          path: RoutePath.UNSUPPORTED,
          payload: undefined,
          baseURL,
        });
    }
  };

  toPathname(): string {
    switch (this.path) {
      case RoutePath.COLLECTION: {
        const { slug } = this.payload as RoutePayloadMap[RoutePath.COLLECTION];
        return `/${this.path.toLocaleLowerCase()}/${slug}`;
      }
      case RoutePath.CATEGORY: {
        const { slug } = this.payload as RoutePayloadMap[RoutePath.CATEGORY];
        return `/${this.path.toLocaleLowerCase()}/${slug}`;
      }
      case RoutePath.SUB_CATEGORY: {
        const { slug, parentSlug } = this
          .payload as RoutePayloadMap[RoutePath.SUB_CATEGORY];
        return `/${RoutePath.CATEGORY.toLocaleLowerCase()}/${parentSlug}/${slug}`;
      }
      case RoutePath.APP_PAGE: {
        const { slug } = this.payload as RoutePayloadMap[RoutePath.APP_PAGE];
        return `/web-solution/${slug}`;
      }
      case RoutePath.SEARCH_RESULTS:
        return `/search-result`;
      case RoutePath.DEVELOPER:
        const { slug } = this.payload as RoutePayloadMap[RoutePath.DEVELOPER];
        return `/developer/${slug}`;
      default:
        return `/`;
    }
  }

  toSearch(): string {
    const payload = (this.payload as Partial<QueryMap>) || {};

    const search = stringify({
      query: payload.query,
      referral: payload.referral,
      referralInfo: payload.referralInfo,
      appIndex: payload.appIndex,
      referralTag: payload.referralTag,
      collimp_id: payload.collimp_id,
      referralSectionName: payload.referralSectionName,
      section: payload.section,
      searchLocation: payload.searchLocation,
      utm_id: payload.utm_id,
      subCat: payload.subCat,
    });

    return search;
  }

  toHref(): string {
    const baseURL = this.baseURL || '';
    const pathName = this.toPathname();
    const search = this.toSearch();

    if (search) {
      return `${baseURL}${pathName}?${search}`;
    }
    return `${baseURL}${pathName}`;
  }

  toOldRoute(): OldRoute {
    switch (this.path) {
      case RoutePath.COLLECTION:
        return {
          path: Path.COLLECTION,
          slug: (this.payload as RoutePayloadMap[RoutePath.COLLECTION]).slug,
        };
      case RoutePath.APP_PAGE:
        return {
          path: Path.WEB_SOLUTION,
          slug: (this.payload as RoutePayloadMap[RoutePath.APP_PAGE]).slug,
        };
      case RoutePath.CATEGORY:
        return {
          path: Path.CATEGORY,
          slug: (this.payload as RoutePayloadMap[RoutePath.CATEGORY]).slug,
        };
      case RoutePath.SUB_CATEGORY:
        return {
          path: Path.CATEGORY,
          slug: (this.payload as RoutePayloadMap[RoutePath.SUB_CATEGORY])
            .parentSlug,
          subCategories: [
            (this.payload as RoutePayloadMap[RoutePath.SUB_CATEGORY]).slug,
          ],
        };
      case RoutePath.FINISH_SETUP:
        return {
          path: Path.FINISH_SETUP,
          slug: (this.payload as RoutePayloadMap[RoutePath.FINISH_SETUP]).appId,
          version: (this.payload as RoutePayloadMap[RoutePath.FINISH_SETUP])
            .version,
        };

      case RoutePath.SEARCH_RESULTS:
        return {
          path: Path.SEARCH,
          query: (this.payload as RoutePayloadMap[RoutePath.SEARCH_RESULTS])
            .query,
        };
      case RoutePath.DEVELOPER:
        return {
          path: Path.DEVELOPER,
          slug: (this.payload as RoutePayloadMap[RoutePath.DEVELOPER]).slug,
        };
      case RoutePath.MANAGE_APPS:
        return {
          path: Path.MANAGE_APPS,
        };
      case RoutePath.HOME:
        return {
          path: Path.HOME,
        };
      default:
        return {};
    }
  }

  equals(route: IRoute): boolean {
    return (
      this.path === route.path &&
      JSON.stringify(this.payload) === JSON.stringify(route.payload)
    );
  }

  toJSON(): IRoute {
    return { path: this.path, payload: this.payload } as IRoute;
  }
}
