import React from 'react';
import { AppsSection } from '@wix/app-market-components';
import {
  useEssentials,
  useJunkYard,
  useRouterContext,
  useServices,
} from '../../../../contexts';
import { Path } from '../../../../models/junk-yard';
import { useAppsSectionSharedPropsMapper } from '../../../../hooks/apps-section-props-mapper';
import { AppMarketPage } from '../../../../enums';
import { useTranslation } from '../../../../contexts/translations-context';
import { QueryDecorator } from '../../../../decorators';
import { CenteredLoader } from '../../../../components/centered-loader';
import { getRecommendedForYouSection } from './recommended-for-you-section-api';
import { appPageDataHooks } from '../../data-hooks';
import { RoutePath } from '../../../../models';

export interface RecommendedForYouSectionProps {
  appId: string;
}

export function RecommendedForYouSection({
  appId,
}: RecommendedForYouSectionProps) {
  const { t } = useTranslation();
  const { goto } = useJunkYard();
  const services = useServices();
  const { router } = useRouterContext();
  const { experiments } = useEssentials();
  const appsSectionSharedPropsMapper = useAppsSectionSharedPropsMapper();
  const page = AppMarketPage.APP_PAGE;

  function onButtonClick(slug: string) {
    if (experiments.enabled('specs.marketplace.routerOnNewInfra')) {
      router.navigateTo({
        path: RoutePath.COLLECTION,
        payload: {
          slug,
        },
      });
    } else {
      goto({ path: Path.COLLECTION, slug });
    }
  }

  return (
    <QueryDecorator
      queryFn={() => getRecommendedForYouSection({ services })}
      queryKey={appId}
    >
      {({ data: appGroup, isLoading }) => {
        if (isLoading || !appGroup) {
          return <CenteredLoader />;
        }

        return (
          <AppsSection
            {...appsSectionSharedPropsMapper(
              appGroup,
              page,
              page,
              0,
              appId,
              undefined,
              {
                path: RoutePath.COLLECTION,
                payload: {
                  slug: appGroup?.tagSlug,
                  referral: 'view_more_apps',
                },
              },
            )}
            title={t('solution.page.YouMayAlsoLike')}
            onButtonClick={() => onButtonClick(appGroup?.tagSlug)}
            dataHook={appPageDataHooks.RECOMMENDED_FOR_YOU_SECTION}
          />
        );
      }}
    </QueryDecorator>
  );
}
