import * as React from 'react';
import s from './app-promo-grid.scss';
import { AppPromoBox } from '../../components/app-promo-box';
import type {
  ExternalComponentProps,
  ComponentsWithSiteContextProps,
} from '../types';
import { withEssentialsContext } from '../../contexts/essentials-context';
import { VisibilityDetector } from '../visibility-detector';
import { v4 as generateUuidV4 } from 'uuid';
import { withSiteContext } from '../../contexts/site-context';
import { Carousel } from '@wix/design-system';
import type { BaseApp } from '../../types';

export interface AppPromoGridProps
  extends ExternalComponentProps,
    ComponentsWithSiteContextProps {
  dataHook?: string;
  apps: BaseApp[];
  onAppClick?(app: BaseApp, appIndex: number, impressionId: string): void;
  onAppLoad?(app: BaseApp, appIndex: number, impressionId: string): void;
  getHref?(appSlug: string, appIndex: number): string | undefined;
}

function AppPromoGridComponent({
  dataHook,
  apps,
  onAppClick,
  onAppLoad,
  getHref,
}: AppPromoGridProps) {
  const renderAppPromoBoxes = () => {
    return apps.slice(0, 4).map((app, index) => {
      const impressionId = generateUuidV4();

      return (
        <VisibilityDetector
          key={index}
          onFirstVisible={() => {
            app && onAppLoad?.(app, index, impressionId);
          }}
          style={{ height: '100%' }}
        >
          <AppPromoBox
            dataHook={`${dataHook}-app-promo-box-container-${app.id}`}
            appData={app}
            onClick={() => onAppClick?.(app, index, impressionId)}
            href={getHref?.(app.slug!, index)}
          />
        </VisibilityDetector>
      );
    });
  };

  return (
    <div data-hook={dataHook}>
      <Carousel
        className={s.promoCarousel}
        variableWidth={true}
        dots={false}
        controlsPosition="none"
        infinite={true}
      >
        {renderAppPromoBoxes()}
      </Carousel>
      <div className={s.promoGrid}>{renderAppPromoBoxes()}</div>
    </div>
  );
}

export const AppPromoGrid = withSiteContext(
  withEssentialsContext(AppPromoGridComponent),
);
