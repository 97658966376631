import React from 'react';
import {
  Box,
  Button,
  Heading,
  Modal,
  ModalMobileLayout,
  Text,
} from '@wix/design-system';
import MobileInstallBlockedImage from '../../../assets/images/mobile-install-blocked';
import { useTranslation } from '../../../contexts/translations-context';
import { useEssentials } from '../../../contexts';
import { appMarketMobileAlertForInstallMoveToDesktop } from '@wix/bi-logger-market/v2';

export interface MobileInstallationBlockedModalProps {
  app: {
    id: string;
    name: string;
  };
  onClose(): void;
}

export function MobileInstallationBlockedModal({
  app,
  onClose,
}: MobileInstallationBlockedModalProps) {
  const { t } = useTranslation();
  const { biLogger } = useEssentials();

  React.useEffect(() => {
    biLogger.report(
      appMarketMobileAlertForInstallMoveToDesktop({
        app_id: app.id,
      }),
    );
  }, []);

  return (
    <Modal isOpen={true} zIndex={100000} screen="full">
      <ModalMobileLayout
        onCloseButtonClick={onClose}
        content={
          <Box
            direction="vertical"
            align="center"
            textAlign="center"
            marginTop="103px"
            marginLeft="24px"
            marginRight="24px"
          >
            <MobileInstallBlockedImage />
            <Box marginTop="46px" />
            <Heading size="large">
              {t('modals-installationNotSupportedMobile-title')}
            </Heading>
            <Box marginTop="12px" marginBottom="36px">
              <Text skin="standard" secondary weight="thin">
                {t('modals-installationNotSupportedMobile-content', {
                  appName: app.name,
                })}
              </Text>
            </Box>
            <Button onClick={onClose}>
              {t('modals-installationNotSupportedMobile-cta')}
            </Button>
          </Box>
        }
        fullscreen
      />
    </Modal>
  );
}
