import s from '../pages.scss';
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Divider,
  Loader,
  Page,
  Text,
  TextButton,
  Heading,
  Breadcrumbs,
} from '@wix/design-system';
import { AppsSection } from '@wix/app-market-components';
import {
  useEssentials,
  useJunkYard,
  useRouterContext,
  useServices,
  useSiteContext,
} from '../../contexts';
import { QueryDecorator } from '../../decorators';
import { getSubCategoryPageData, getApps } from './sub-category-page-data';
import type { SubCategoryPageData } from './sub-category-page-data';
import { useAppsSectionSharedPropsMapper } from '../../hooks/apps-section-props-mapper';
import { AppMarketPage } from '../../enums';
import { useTranslation } from '../../contexts/translations-context';
import type { AppGroupSection, AppDataType } from '@wix/app-market-services';
import { getPageViewBiData } from '../../bi-services/events-data-parser';
import {
  appMarketBackButtonClick,
  appMarketPageView,
} from '@wix/bi-logger-market/v2';
import {
  appMarketCategoryClickSrc24Evid185,
  appMarketPageStartLoadedSrc24Evid163,
} from '@wix/bi-logger-app-market-data/v2';
import { Route, RoutePath } from '../../models';
import { Path } from '../../models/junk-yard';
import { SubCategorySkeleton } from './sub-category-skeleton';
import { BackButton } from '../../components/back-button';

function SubCategoryPageComponent({
  slug,
  appGroup,
  paging,
  tagId,
  primaryTitle: title,
  parentSlug,
  parentName,
}: SubCategoryPageData) {
  const appsSectionSharedPropsMapper = useAppsSectionSharedPropsMapper();
  const { routerData, goto } = useJunkYard();
  const { baseURL } = useSiteContext();
  const { biLogger, experiments } = useEssentials();
  const { router } = useRouterContext();
  const { t } = useTranslation();
  const services = useServices();
  const page = AppMarketPage.SUB_CATEGORY;
  const ref = useRef<HTMLDivElement>(null);
  const [apps, setApps] = useState<AppDataType[]>(
    appGroup.apps as AppDataType[],
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [moreAppsToLoad, setMoreAppsToLoad] = useState<boolean>(paging.hasNext);

  function onBack() {
    biLogger.report(
      appMarketBackButtonClick({
        tag_type: page,
        tag_name: slug,
        location: 'sub_category_page',
      }),
    );
    router.navigateTo({
      path: RoutePath.BACK,
      payload: undefined,
    });
  }

  async function fetchMoreApps() {
    setIsLoading(true);
    const { apps: nextApps, hasNext } = await getApps({
      services,
      tagId,
      offset: apps.length,
    });
    setIsLoading(false);

    setMoreAppsToLoad(nextApps.length > 0 && hasNext);
    setApps((prevApps) => [...prevApps, ...nextApps]);
  }

  function onIntersection(entries: IntersectionObserverEntry[]) {
    const isIntersecting = entries.some((entry) => entry.isIntersecting);
    if (isIntersecting && moreAppsToLoad) {
      fetchMoreApps();
    }
  }

  function getCategoryHref(categorySlug: string) {
    const hasHref = router.hasHref();
    if (
      !experiments.enabled('specs.marketplace.routerOnNewInfra') ||
      !hasHref
    ) {
      return undefined;
    }
    const route = new Route({
      path: RoutePath.CATEGORY,
      payload: {
        slug: categorySlug,
      },
      baseURL,
    });
    return route.toHref();
  }

  useEffect(() => {
    const urlParams = location.pathname.split('/')[3];
    biLogger.report(
      appMarketPageView(
        getPageViewBiData({
          routerData,
          urlParams,
          pageType: AppMarketPage.SUB_CATEGORY,
          pageName: parentName,
          subCategories: [title],
        }),
      ),
    );
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(onIntersection);
    if (moreAppsToLoad) {
      observer.observe(ref.current!);
    }
    return () => {
      observer.disconnect();
    };
  }, [apps]);

  function onButtonClick(slug: string) {
    biLogger.report(
      appMarketCategoryClickSrc24Evid185({
        tag_type: 'category',
        tag_name: parentSlug,
        sub_tag_name: slug,
      }),
    );
    if (experiments.enabled('specs.marketplace.routerOnNewInfra')) {
      router.navigateTo({
        path: RoutePath.CATEGORY,
        payload: {
          slug,
        },
      });
    } else {
      goto({ path: Path.CATEGORY, slug: parentSlug });
    }
  }

  return (
    <Page className={`${s.appMarketPage} ${s.appMarketPageBackgroundColor}`}>
      <Page.Content>
        <Box dataHook="breadcrumbs" marginTop="24px">
          {router.hasHistory() && (
            <>
              <Box verticalAlign="middle">
                <BackButton
                  onBack={onBack}
                  size="small"
                  weight="thin"
                  skin="dark"
                />
              </Box>
              <Box margin="0px 12px 0px 18px" height="12px" alignSelf="center">
                <Divider direction="vertical" />
              </Box>
            </>
          )}
          <Breadcrumbs
            items={[
              {
                id: 0,
                value: 'Category',
                customElement: (
                  <TextButton
                    dataHook="category-parent-button"
                    size="small"
                    weight="normal"
                    as="a"
                    href={getCategoryHref(parentSlug)}
                    onClick={(e) => {
                      onButtonClick(parentSlug);
                      e.preventDefault();
                    }}
                  >
                    {parentName}
                  </TextButton>
                ),
              },
              {
                id: 1,
                value: title,
                customElement: (
                  <Text weight="thin" size="small" secondary disabled>
                    {title}
                  </Text>
                ),
              },
            ]}
            size="medium"
          />
        </Box>
        <Box marginTop="6px">
          <Heading size="extraLarge" dataHook="sub-category-page-title">
            {title}
          </Heading>
        </Box>
        <Box marginTop="24px" marginBottom="-6px">
          <Text weight="normal" size="small" secondary>
            {t('collection.apps-counter', { appsCount: paging.total })}{' '}
          </Text>
        </Box>
        <Box
          ref={ref}
          dataHook="sub-category-page-apps-section-box"
          direction="vertical"
        >
          <AppsSection
            {...appsSectionSharedPropsMapper(
              { ...appGroup, apps: apps as AppGroupSection['apps'] },
              page,
              slug,
              0,
            )}
          ></AppsSection>
        </Box>
        {isLoading && (
          <Box verticalAlign="middle" align="center" marginTop="24px">
            <Loader size="medium" />
          </Box>
        )}
      </Page.Content>
    </Page>
  );
}

export function SubCategoryPage({
  slug,
  parentSlug,
}: {
  slug: string;
  parentSlug: string;
}) {
  const { biLogger, i18n } = useEssentials();
  const services = useServices();

  useEffect(() => {
    biLogger.report(
      appMarketPageStartLoadedSrc24Evid163({
        tag_type: AppMarketPage.SUB_CATEGORY,
      }),
    );
  }, [slug, parentSlug]);

  return (
    <QueryDecorator
      queryFn={() =>
        getSubCategoryPageData({
          slug,
          parentSlug,
          services,
          languageCode: i18n.language,
        })
      }
      queryKey={`sub-category-${slug}-${i18n.language}`}
    >
      {({ data: subCategoryPageData, isLoading }) =>
        isLoading ? (
          <SubCategorySkeleton />
        ) : (
          <SubCategoryPageComponent {...subCategoryPageData} />
        )
      }
    </QueryDecorator>
  );
}
