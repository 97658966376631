import React from 'react';
import { Box, Button, Loader, Tooltip } from '@wix/design-system';
import type { ButtonPriority, ButtonSize } from '@wix/design-system';
import { openAppClick } from '@wix/bi-logger-market/v2';
import type { ManagedApp } from '@wix/app-market-services';
import { useTranslation } from '../../../../contexts/translations-context';
import {
  useEssentials,
  useInstaller,
  useJunkYard,
  useRouterContext,
  useServices,
  useSiteContext,
  useUserContext,
} from '../../../../contexts';
import { getOpenAppClickBiData } from '../../../../bi-services/events-data-parser';
import { RoutePath } from '../../../../models';
import s from './open-app-button.scss';
import { QueryDecorator } from '../../../../decorators';

export interface IOpenAppButtonProps {
  managedApp?: ManagedApp;
  isDisabled: boolean;
  tooltipContent: string;
  app: {
    id: string;
    isBuiltIn: boolean;
  };
  size: ButtonSize;
  priority: ButtonPriority;
  openMobileInstallationBlockedModal(): void;
}

export function OpenAppButton({
  managedApp: existingManagedApp,
  isDisabled,
  tooltipContent,
  app,
  size,
  priority,
  openMobileInstallationBlockedModal,
}: IOpenAppButtonProps) {
  const { t } = useTranslation();
  const { metaSiteId } = useSiteContext();
  const { isMobile } = useUserContext();
  const services = useServices();
  const { biLogger } = useEssentials();
  const { router } = useRouterContext();
  const { routerData, goBackToReferrer, openApp } = useJunkYard();
  const installer = useInstaller();

  return (
    <QueryDecorator
      queryFn={async () => {
        if (!metaSiteId) {
          return;
        }
        if (existingManagedApp) {
          return existingManagedApp;
        }
        const managedApps = await services.getManagedApps({});
        return managedApps.findByAppId(app.id);
      }}
      queryKey={`open-app-button-${app.id}`}
      csrOnly
    >
      {({ data: managedApp, isLoading }) => {
        const isAddAgainMode =
          !!managedApp?.isAddAgainAllowed && !!installer.addAgain;

        return (
          <Tooltip
            className={s.tooltip}
            inline
            disabled={!tooltipContent}
            content={tooltipContent}
          >
            <Box direction="vertical" flexGrow={1}>
              <Button
                disabled={isDisabled}
                size={size}
                priority={priority}
                onClick={() => {
                  if (isLoading) {
                    return;
                  }

                  biLogger.report(
                    openAppClick(
                      getOpenAppClickBiData({
                        appId: app.id,
                        ctaType: isAddAgainMode ? 'add_again' : 'open_app',
                        tagType: 'app_page',
                        tagName: app.id,
                        routerData,
                        referrerNameForBI: goBackToReferrer?.referrerNameForBI,
                      }),
                    ),
                  );

                  if (isMobile) {
                    return openMobileInstallationBlockedModal();
                  }

                  if (app.isBuiltIn) {
                    return router.navigateTo({
                      path: RoutePath.WIX_OFFERING,
                      payload: {
                        appId: app.id,
                      },
                    });
                  }

                  if (isAddAgainMode) {
                    return installer.addAgain?.({
                      appId: app.id,
                      appVersion: managedApp.installedVersion,
                    });
                  }

                  const managedAppJson = managedApp?.toJSON();
                  managedAppJson && openApp(managedAppJson);
                }}
              >
                {isLoading ? (
                  <Loader size="tiny" />
                ) : isAddAgainMode ? (
                  t('solution.page.addAgain')
                ) : (
                  t('solution.page.open')
                )}
              </Button>
            </Box>
          </Tooltip>
        );
      }}
    </QueryDecorator>
  );
}
