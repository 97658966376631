import type { ManagedApp } from '@wix/ambassador-devcenter-managedapps-v1-managed-app/types';

export enum Path {
  CATEGORY = 'category',
  WEB_SOLUTION = 'web-solution',
  COLLECTION = 'collection',
  SEARCH = 'search-result',
  SUB_CATEGORY = 'sub-category',
  HOME = '',
  SHARE = 'share-app',
  FINISH_SETUP = 'finish-setup',
  FINISH_UPDATE = 'finish-update',
  MANAGE_APPS = 'manage-apps',
  RECOMMENDATION = 'recommendation',
  DEVELOPER = 'developer',
  OPEN_APP_WITH_PARAMS = 'open-app-with-params',
}

export interface IRoute {
  path?: Path | string;
  subCategories?: string[];
  referral?: string;
  referralSectionName?: string;
  query?: string;
  slug?: string;
  appIndex?: number;
  version?: string;
}
export interface RouterData {
  route: IRoute;
  prevRoute: IRoute;
}

export interface INotificationsOptions {
  message: string;
  biName: string;
  type?: string;
}

export interface JunkYard {
  routerData: RouterData;
  goBackToReferrer?: {
    navigation(): {} | void;
    labelKey: string;
    referrerNameForBI: string;
  };
  goto: (routeData: {
    path: Path;
    referral?: string;
    referralSectionName?: string;
    subCategories?: string[];
    collimp_id?: string;
    slug?: string;
    appIndex?: number;
    query?: string;
    searchLocation?: string;
  }) => void;
  ssrMemo?: Record<string, any>;
  showUserActionNotification: (options: INotificationsOptions) => void;
  openApp: (app: ManagedApp) => void;
}
