import {
  manageAppsUpgradeSite,
  appMarketClickOnFreeTrialCtaInManageAppsPage,
} from '@wix/bi-logger-app-market-data/v2';
import { useEssentials } from '../../../../contexts';

export function useUpgradeButtonBiEvents(app: {
  id: string;
  instanceId?: string;
}) {
  const { biLogger } = useEssentials();

  return {
    reportUpgradeClick: () => {
      biLogger.report(
        manageAppsUpgradeSite({
          appId: app.id,
          appInstanceId: app.instanceId,
          upgrade_origin: 'app_page',
        }),
      );
    },
    reportFreeTrialClick: () => {
      biLogger.report(
        appMarketClickOnFreeTrialCtaInManageAppsPage({
          appId: app.id,
          appInstanceId: app.instanceId,
          origin: 'app_page',
        }),
      );
    },
  };
}
